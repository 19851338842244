import React from "react";
import dentnetLogo from "../../assets/logo-dentnet.png"; // or .svg, depending on your logo

const Spinner = () => {
  return (
    <svg
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{ background: "none" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* 1) The logo in the center */}
      <image
        href={dentnetLogo}
        x="25"       // Adjust these to center your specific logo size
        y="25"
        width="50"
        height="50"
      />

      {/* 2) The rotating path */}
      <path
        d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
        fill="#93C300"
        transform="rotate(0, 50, 51)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 51;360 50 51"
          keyTimes="0;1"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default Spinner;