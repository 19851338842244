export const getQuestionTypeName = (type) => {
  const typeMap = {
    dentures: "Vergünstigter Zahnersatz",
    toothCorrection: "Vergünstigte Zahnkorrektur",
    pzr: "Professionelle Zahnreinigung",
    pzrannual: "Professionelle Zahnreinigung",
    pzrsemiannual: "PZR halbjährlich",
    pzrpregnant: "Professionelle Zahnreinigung Schwangere",
    crown: "iKrone",
    prothesis: "Zahnersatz",
    orthodontic: "Zahnkorrektur",
    implant: "Implantate",
    fluVaccine: "Grippeschutzimpfung",
    travelVaccine: "Reiseschutzimpfung",
    healthCourses: "Gesundheitskurse",
    onlineCourses: "Online-Kurse",
    fitnessTracker: "Fitness-Tracker",
    acupuncture: "Akupunktur",
    homeopathicMedicine: "Homöopath. Medikamente",
    skinCancer: "Hautkrebsvorsorge",
    colorectalCancer: "Darmkrebsvorsorge",
    breastCancer: "Brustkrebsvorsorge",
    medicalSportExamination: "Med. Sportuntersuchung",
    artificialInsemination: "Künstliche Befruchtung",
    childrenInsepection: "Kinder-Gesundheitscheck",
    midWifeServices: "Hebammenrufbereitschaft",
    osteopathy: "Osteopath. Behandlung",
    homeopathy: "Homöopath. Behandlung",
    hotline: "Servicetelefon 24/7",
    pregnancyCare: "Schwangerschaftsvorsorge",
    telemedicine: "Tele-Medizin",
  };
  return typeMap[type] || type;
};
