import React from "react";
import { getQuestionTypeName } from "../utils/questionTypeTranslator";
import { Tooltip } from "react-tooltip";
import { getTooltipText } from "../utils/tooltips";
import ToolTipContentServices from "./ui/toolTipContentServices";
import { noTooltipServices } from "../constants/noTooltipServices";

const FilterCategory = React.memo(
  ({ sectionTitle, questionTypes, onFilterChange, activeFilters }) => {
    const getSectionTitle = (title) => {
      const titleMap = {
        teeth: "Zähne",
        vaccine: "Impfungen",
        sports: "Sport & Bewegung",
        medicine: "Alternative Medizin",
        cancer: "Krebsvorsorge",
        artificial: "Familie",
        service: "Service",
        dentnet_default: "Zähne",
      };
      return titleMap[title] || title;
    };

    return (
      <div className="border-b border-gray-300 pb-4 pt-4">
        <h3 className="text-lg font-semibold ">
          {getSectionTitle(sectionTitle)}
        </h3>
        {questionTypes.map((type) => (
          <div
            key={type}
            className="mb-2 flex items-center justify-between whitespace-nowrap"
          >
            <label className="flex items-center space-x-2 cursor-pointer flex-shrink-0">
              <input
                type="checkbox"
                checked={activeFilters.has(type)}
                onChange={() => onFilterChange(type)}
                className="form-checkbox h-5 w-5 text-blue-600 flex-shrink-0"
              />
              <span className="text-gray-700">{getQuestionTypeName(type)}</span>
            </label>
            {!noTooltipServices.includes(type) && (
              <div className="flex-shrink-0 ml-2">
                <span
                  data-tooltip-id={`tooltip-service-${type}`}
                  data-tooltip-content={type}
                  className="cursor-help inline-block w-4 h-4 text-xs bg-gray-300 rounded-full text-center text-gray-600 break-normal"
                >
                  ?
                </span>
                <Tooltip
                  id={`tooltip-service-${type}`}
                  place="top"
                  effect="solid"
                  className="z-100"
                  style={{
                    backgroundColor: "transparent",
                    padding: 0,
                    
                  }}
                  render={({ content }) => (
                    <ToolTipContentServices content={content} />
                  )}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
);

export default FilterCategory;
