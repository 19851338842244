const show_discounted = [
  "prothesis",
];

const show_subsidized = ["orthodontic", "implant"];

const show_pzr_info=["pzrannual"];

export const extractServiceNumbers = (section, questionId) => {
  let result = "";
  let foundQuestion1or2 = false;

  // Extract the questions array from the section
  const questions = section.questions;

  // Helper function to find a question by its ID
  const findQuestionById = (id) => questions.find((q) => q.id === id);

  // Function to check if both valueOption1 and valueOption2 are non-empty
  const bothOptionsExist = (question) => {
    return (
      question.valueOption1 &&
      question.valueOption1.trim() !== "" &&
      question.valueOption2 &&
      question.valueOption2.trim() !== ""
    );
  };

  if (show_discounted.includes(questionId)) return "Vergünstigt und 5 Jahre Garantie";
  if (show_subsidized.includes(questionId)) return "Zu Festpreisen";
  if (show_pzr_info.includes(questionId)) return "Übernahme der vollen Kosten bei Dentnet Praxen";

  // Check for questionId_1
  const question1 = findQuestionById(`${questionId}_1`);
  if (question1) {
    foundQuestion1or2 = true;
    if (bothOptionsExist(question1)) {
      result =
        "Höhe: " +
        question1.valueOption1 +
        " / Häufigkeit: " +
        question1.valueOption2;
    } else {
      result = "wird übernommen";
    }
  } else {
    // If questionId_1 is not found, check for questionId_2
    const question2 = findQuestionById(`${questionId}_2`);
    if (question2) {
      foundQuestion1or2 = true;
      if (bothOptionsExist(question2)) {
        result =
          "Höhe: " +
          question2.valueOption1 +
          " / Häufigkeit: " +
          question2.valueOption2;
      } else {
        result = "wird übernommen";
      }
    }
  }

  // If either questionId_1 or questionId_2 was found
  if (foundQuestion1or2) {
    // Check for questionId_3 and append its value if it exists
    const question3 = findQuestionById(`${questionId}_3`);
    if (question3 && question3.value && question3.value.trim() !== "") {
      result += " " + question3.value;
    }
  } else {
    // If neither questionId_1 nor questionId_2 was found
    result = "wird übernommen";
  }

  return result;
};
