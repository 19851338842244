const baseUrl = process.env.REACT_APP_API_BASEURL
const apiKey = process.env.REACT_APP_API_KEY

//get all insurance
export const getAllInsurance = async (type='all') => {
    return (await fetch(baseUrl + `/get/insurances/${type}`, {
        method: "get",
        headers: {"Authorization": "Bearer " + apiKey}
    })).json()
}

//set obj insurance
export const saveContactForms = async (data) => {
    try {
      console.log("Sending data to API:", data);
      const response = await fetch(baseUrl + "/create/new-application", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + apiKey,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log("API response:", result);
      return result;
    } catch (error) {
      console.error("Error in saveContactForms:", error);
      throw error;
    }
  };

//get all application
export const getAllApplication = async () => {
    return (await fetch(baseUrl + "/get/application", {
        method: "get",
        headers: {"Authorization": "Bearer " + apiKey}
    })).json()
}
