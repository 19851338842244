import React from "react";
import CurrentInsuranceCard from "./CurrentInsuranceCard";
import InsuranceCard from "./InsuranceCard";
import Spinner from "./ui/Spinner";

const ResultsSection = ({
  currentInsurance,
  insurances,
  isCurrentInsuranceVisible,
  activeFilters,
  allInsurances,
}) => {
  console.log(activeFilters);

  const groupedQuestions = allInsurances?.reduce((acc, insurance) => {
    insurance?.sections?.forEach((section) => {
      if (!acc[section.title]) {
        acc[section.title] = new Set();
      }
      section.questions.forEach((question) => {
        acc[section.title].add(question.id.split("_")[0]);
      });
    });

    return acc;
  }, {});
  console.log("sizes:", groupedQuestions);

  return (
    <div className="w-full lg:w-2/3">
      {isCurrentInsuranceVisible && currentInsurance && (
        <CurrentInsuranceCard
          insurance={currentInsurance}
          sectionSizes={groupedQuestions} //used for the grade calculation
        />
      )}

      {insurances.map((insurance, index) =>
        insurance.isdentnet ? (
          <InsuranceCard
            currentInsuranceName={currentInsurance.companyName}
            key={insurance._id}
            index={insurance.index}
            insurance={insurance}
            isCurrentInsurance={false}
            sectionSizes={groupedQuestions} //used for the grade calculation
          />
        ) : null
      )}
      {insurances.length === 0 && activeFilters?.size === 0 && (
        <div className="flex flex-col items-center justify-center py-8">
          <Spinner />
        </div>
      )}

      {insurances.length === 0 && activeFilters?.size > 0 && (
        <div className="flex flex-col items-center justify-center py-8">
          <span>
            Für Ihre ausgewählten Filter konnten wir leider keine Ergebnisse
            finden...
          </span>
        </div>
      )}
    </div>
  );
};

export default ResultsSection;
