const ToolTipContentServices = ({ content }) => {
  const renderTemplate = () => {
    switch (content) {
      case "dentures" && "prothesis":
        return (
          <div className="break-normal">
            <h2 className="font-bold my-2">Zahnersatz</h2>
            <p className="break-words whitespace-normal">
              Zahnersatz ersetzt fehlende oder beschädigte Zähne,um die Funktion
              und Ästhetik des Gebisses wiederherzustellen. Es gibt zwei
              Hauptarten: Festsitzender Zahnersatz: Kronen, Brücken und
              Implantate, die dauerhaft im Mund verankert sind. Herausnehmbarer
              Zahnersatz: Prothesen, die je nach Bedarf eingesetzt und entfernt
              werden können, oft bei mehreren fehlenden Zähnen. Die Materialien
              reichen von Metall über Keramik bis hin zu Kunststoffen, die sich
              ästhetisch und funktional an Ihre natürlichen Zähne anpassen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Die gesetzliche Krankenkasse übernimmt einen Festzuschuss für
              Zahnersatz. Dieser Festzuschuss beträgt in der Regel 60 % der
              Kosten der sogenannten Regelleistung. Wenn ein lückenlos geführtes
              Bonusheft vorhanden ist, kann der Zuschuss auf 70 % (nach fünf
              Jahren) oder sogar 75 % (nach zehn Jahren) steigen. Im DentNet
              wird für Versicherte teilnehmender Krankenkassen, die
              Regelversorgung zu 100 % bezuschusst, wenn das Bonusheft 10 Jahre
              lückenlos geführt wurde. Die Regelleistung deckt Basisbehandlungen
              ab, bei teureren Behandlungen müssen Sie den Eigenanteil selbst
              tragen. Wenn Sie jedoch über zusätzliche private Versicherungen
              verfügen, können die verbleibenden Kosten teilweise oder
              vollständig erstattet werden.
            </p>
          </div>
        );
      case "implant":
        return (
          <div className="break-normal">
            <h2 className="font-bold my-2">Zahnimplantate – dentalImplants</h2>
            <p className="break-words whitespace-normal">
              Zahnimplantate sind künstliche Zahnwurzeln, die fest im
              Kieferknochen verankert werden, um fehlende Zähne zu ersetzen oder
              Prothesen stabil zu befestigen. Sie bestehen meist aus Titan oder
              Keramik und setzen sich aus drei Komponenten zusammen: der
              Implantatschraube, die in den Kiefer eingesetzt wird, dem
              Verbindungsstück (Abutment) und der darauf befestigten Zahnkrone.
              Die Implantation wird präzise geplant und individuell an den
              Kiefer angepasst. Nach dem Einsetzen wächst das Implantat
              innerhalb weniger Monate in den Knochen ein. Anschließend kann die
              Krone, Brücke oder Prothese dauerhaft darauf befestigt werden.
              Zahnimplantate stellen eine langfristige Lösung dar, da sie die
              natürliche Funktion und Ästhetik der Zähne wiederherstellen. Bei
              guter Mundhygiene können sie 15 bis 20 Jahre oder länger halten.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Die gesetzliche Krankenkasse übernimmt einen Festzuschuss für
              Zahnersatz, jedoch nicht für das Implantat selbst. Dieser Zuschuss
              richtet sich nach der Regelversorgung, die meist konventionelle
              Brücken oder Prothesen umfasst. Er beträgt 60 % der Kosten, kann
              aber mit einem über fünf oder zehn Jahre geführten Bonusheft auf
              70 % bzw. 75 % steigen. Implantate sind in der Regel privat zu
              finanzieren. Allerdings können private Zusatzversicherungen oder
              spezielle Zahnzusatzversicherungen die Kosten ganz oder teilweise
              übernehmen.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Zahnimplantate aus dem DentNet sind hochwertige Qualitätsprodukte
              und werden mit einer verlängerten Garantie von fünf Jahren
              ausgeliefert. Erfahrene Zahntechnikermeisterinnen und
              Zahntechnikermeister verwenden ausschließlich geprüfte,
              hochwertige Materialien, überwachen den gesamten
              Herstellungsprozess und kontrollieren jedes Implantat vor der
              Auslieferung an Ihre Zahnarztpraxis. Die Fertigung erfolgt nach
              einem TÜV-zertifizierten Qualitätsmanagement (DIN ISO 9001:2015).
            </p>
          </div>
        );
      case "toothCorrection" && "orthodontic":
        return (
          <div>
            <h2 className="font-bold  my-2">Zahnkorrektur</h2>
            <p className="break-words whitespace-normal">
              Zahnkorrekturen beheben Fehlstellungen der Zähne und verbessern so
              nicht nur das Aussehen, sondern auch die Funktion der Zähne und
              des Gebisses. Sie fördern gesünderes Kauen, Sprechen und Atmen und
              senken das Risiko für Zahnerkrankungen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Die Krankenkassen übernehmen die Kosten für eine Zahnkorrektur bei
              Kindern und Jugendlichen bis zum 18. Lebensjahr, wenn eine
              medizinische Notwendigkeit besteht. Bei Erwachsenen werden die
              Kosten in der Regel nicht übernommen, es sei denn, die Behandlung
              ist Teil einer kombinierten kieferchirurgischen Therapie.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Die Kosten für Ihre Zahnkorrektur im DentNet beinhalten die
              medizinischen Leistungen und die Kontrolltermine durch die
              teilnehmende Zahnarztpraxis.
            </p>
          </div>
        );
      case "pzrpregnant":
        return (
          <div>
            <h2 className="font-bold  my-2">
              Professionelle Zahnreinigung (PZR)
            </h2>
            <p className="break-words whitespace-normal">
              Eine professionelle Zahnreinigung (PZR) ist eine umfassende
              Pflegebehandlung, die weit über die häusliche Mundhygiene
              hinausgeht. Hartnäckige Ablagerungen wie Plaque, Zahnstein und
              Verfärbungen werden dabei gründlich entfernt, um Karies und
              Zahnfleischproblemen vorzubeugen. Anschließend werden die Zähne
              poliert und mit Fluorid behandelt, um sie langfristig zu schützen.
              Diese wichtige Prophylaxemaßnahme wird von erfahrenen Fachkräften
              durchgeführt und dauert etwa 30 bis 60 Minuten.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen bieten freiwillige Zuschüsse für die PZR in
              unterschiedlicher Höhe an. Ein gesetzlicher Anspruch auf
              Kostenübernahme besteht jedoch nicht. In zahnmedizinischen Praxen
              des DentNet ist die professionelle Zahnreinigung bis zu zweimal im
              Jahr kostenlos, wenn Sie bei einer teilnehmenden Krankenkasse
              versichert sind.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Bei einer Zahnreinigung im DentNet fallen keine weiteren
              Zuzahlungen in der Zahnarztpraxis an. Als Versicherte der DAK
              Gesundheit zahlen Sie die Zahnreinigung in Höhe von 58 Euro in
              Ihrer Zahnarztpraxis selbst und bekommen sie anschließend von der
              DAK Gesundheit erstattet.
            </p>
          </div>
        );
        case "pzrannual":
        return (
          <div>
            <h2 className="font-bold  my-2">
              Professionelle Zahnreinigung (PZR)
            </h2>
            <p className="break-words whitespace-normal">
              Eine professionelle Zahnreinigung (PZR) ist eine umfassende
              Pflegebehandlung, die weit über die häusliche Mundhygiene
              hinausgeht. Hartnäckige Ablagerungen wie Plaque, Zahnstein und
              Verfärbungen werden dabei gründlich entfernt, um Karies und
              Zahnfleischproblemen vorzubeugen. Anschließend werden die Zähne
              poliert und mit Fluorid behandelt, um sie langfristig zu schützen.
              Diese wichtige Prophylaxemaßnahme wird von erfahrenen Fachkräften
              durchgeführt und dauert etwa 30 bis 60 Minuten.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen bieten freiwillige Zuschüsse für die PZR in
              unterschiedlicher Höhe an. Ein gesetzlicher Anspruch auf
              Kostenübernahme besteht jedoch nicht. In zahnmedizinischen Praxen
              des DentNet ist die professionelle Zahnreinigung bis zu zweimal im
              Jahr kostenlos, wenn Sie bei einer teilnehmenden Krankenkasse
              versichert sind.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Bei einer Zahnreinigung im DentNet fallen keine weiteren
              Zuzahlungen in der Zahnarztpraxis an. Als Versicherte der DAK
              Gesundheit zahlen Sie die Zahnreinigung in Höhe von 58 Euro in
              Ihrer Zahnarztpraxis selbst und bekommen sie anschließend von der
              DAK Gesundheit erstattet.
            </p>
          </div>
        );
      case "fluVaccine":
        return (
          <div>
            <h2 className="font-bold  my-2">Grippeschutz</h2>
            <p className="break-words whitespace-normal">
              Die ständige Impfkommission (STIKO) empfiehlt, die
              Grippeschutzimpfung einmal jährlich aufzufrischen, weil sich die
              Grippeviren ständig verändern. Der beste Zeitpunkt ist zwischen
              Oktober und Dezember, um gut vor der Grippesaison geschützt zu
              sein. Sie ist für Menschen ab 60 Jahren und Risikogruppen, wie
              Schwangere, bei allen Kassen kostenfrei. Einige Kassen bieten die
              Impfung auch für alle Versicherten als Zusatzleistung an.
            </p>
          </div>
        );
      case "travelVaccine":
        return (
          <div>
            <h2 className="font-bold  my-2">Reiseschutzimpfung</h2>
            <p className="break-words whitespace-normal">
              Für Reisen in bestimmte Regionen, wie Asien oder Afrika, empfiehlt
              das Auswärtige Amt Impfungen gegen Infektionskrankheiten.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Ein gesetzlicher Anspruch besteht nicht, allerdings bieten viele
              Krankenkassen jedoch freiwillige Zuschüsse für
              Reiseschutzimpfungen, z. B. gegen Hepatitis A oder Cholera.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Es können geringe Kosten für die ärztliche Behandlung oder den
              Medikamentenkauf anfallen.
            </p>
          </div>
        );
      case "healthCourses":
        return (
          <div>
            <h2 className="font-bold  my-2">Gesundheitsprogramme</h2>
            <p className="break-words whitespace-normal">
              Diese Kurse werden häufig bezuschusst oder komplett erstattet. Sie
              decken Präventionsbereiche wie Bewegung, Ernährung, Entspannung
              und Suchtprävention ab. Typische Inhalte sind Rückenschule,
              Stressbewältigung, Yoga und Pilates.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Kassen übernehmen bis zu 100 % der Kurskosten, insbesondere
              für Programme, die Präventionsmaßnahmen abdecken.
            </p>
          </div>
        );
      case "onlineCourses":
        return (
          <div>
            <h2 className="font-bold  my-2">Onlinekurse</h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen bieten Ihre Gesundheitskurse auch online an.
              So können Sie bequem von zuhause aus teilnehmen. Diese decken
              Präventionsbereiche wie Bewegung, Ernährung, Entspannung und
              Suchtprävention ab. Typische Inhalte sind Rückenschule,
              Stressbewältigung, Yoga und Pilates ab.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Kassen übernehmen bis zu 100 % der Kurskosten, insbesondere
              für Programme, die Präventionsmaßnahmen abdecken.
            </p>
          </div>
        );
      case "fitnessTracker":
        return (
          <div>
            <h2 className="font-bold  my-2">Fitness</h2>
            <p className="break-words whitespace-normal">
              Fitnessprogramme umfassen in der Regel Kurse zu Bewegung, Fitness,
              Ernährung und Stressbewältigung. Viele Krankenkassen unterstützen
              ihre Versicherten finanziell bei der Teilnahme an zertifizierten
              Präventionskursen, die nach § 20 SGB V organisiert sind. Diese
              Programme können sowohl in Fitnessstudios als auch online
              angeboten werden. Einige Krankenkassen bieten auch Fitness-Apps
              oder Online-Fitnesskurse an, die den Nutzern helfen, von zu Hause
              aus aktiv zu bleiben.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Oft übernehmen die Krankenkassen einen Teil oder sogar die
              gesamten Kurskosten, wenn die Teilnahme den präventiven
              Richtlinien entspricht.
            </p>
          </div>
        );
      case "acupuncture":
        return (
          <div>
            <h2 className="font-bold  my-2">Akupunktur</h2>
            <p className="break-words whitespace-normal">
              Akupunktur ist eine sanfte Therapieform der traditionellen
              chinesischen Medizin (TCM). Durch feine Nadelstiche an bestimmten
              Punkten des Körpers wird der Energiefluss angeregt und Blockaden
              gelöst. So können verschiedene Beschwerden gelindert werden.
              Besonders bei Schmerzen und funktionellen Störungen unterstützt
              Akupunktur die körpereigenen Heilkräfte und hilft, das innere
              Gleichgewicht wiederherzustellen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Die Krankenkassen zahlen für Akupunktur, wenn es sich um
              chronische Schmerzen in der Lendenwirbelsäule oder bei
              Kniegelenksarthrose handelt. Einige Krankenkassen übernehmen auch
              weiterführende Akupunkturbehandlungen, wenn sie als medizinisch
              notwendig gelten.
            </p>
          </div>
        );
      case "homeopathy":
        return (
          <div>
            <h2 className="font-bold  my-2">Homöopathische Behandlung</h2>
            <p className="break-words whitespace-normal">
              Eine homöopathische Behandlung beginnt mit einem persönlichen
              Gespräch zwischen dem Patienten und dem Therapeuten. Ziel dieses
              Austauschs ist es, passende homöopathische Mittel zu finden, die
              die Beschwerden lindern können.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Es gibt keinen gesetzlichen Anspruch auf homöopathische
              Behandlungen. Allerdings bieten viele Krankenkassen diese als
              freiwillige Zusatzleistung an und übernehmen einen Teil der
              Kosten.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Behandlungen durch Heilpraktiker werden in der Regel nicht
              übernommen. Behandelnde Ärzte müssen eine Zusatzqualifikation in
              Homöopathie oder eine Mitgliedschaft im Deutschen Zentralverein
              homöopathischer Ärzte (DZVhÄ) nachweisen.
            </p>
          </div>
        );
      case "homeopathicMedicine":
        return (
          <div>
            <h2 className="font-bold  my-2">Homöopathische Arzeimittel</h2>
            <p className="break-words whitespace-normal">
              Homöopathische Medikamente werden nach dem Prinzip „Ähnliches
              heilt Ähnliches“ hergestellt. Das bedeutet, dass eine Substanz,
              die in hoher Dosis Symptome auslöst, in stark verdünnter Form
              ähnliche Beschwerden lindern soll. Diese Mittel werden bei akuten
              oder chronischen Beschwerden eingesetzt, um die
              Selbstheilungskräfte des Körpers anzuregen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Die Kostenübernahme für homöopathische Medikamente ist bei einigen
              Krankenkassen als freiwillige Zusatzleistung möglich. Es gibt
              jedoch Bestrebungen, diese Leistungen aufgrund wissenschaftlicher
              Kontroversen aus dem Leistungskatalog zu streichen.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Bevor Sie diese Leistung in Anspruch nehmen, empfehlen wir Ihnen,
              sich direkt bei Ihrer Krankenkasse zu informieren, um alle Details
              und Voraussetzungen zu klären. So sind Sie bestens vorbereitet.
            </p>
          </div>
        );
      case "osteopathy":
        return (
          <div>
            <h2 className="font-bold  my-2">Osteopathie</h2>
            <p className="break-words whitespace-normal">
              Osteopathie ist eine manuelle Therapieform, bei der Blockaden im
              Bewegungsapparat gezielt mit den Händen gelöst werden. Dadurch
              sollen die Selbstheilungskräfte des Körpers aktiviert und das
              allgemeine Wohlbefinden gesteigert werden.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Es besteht kein gesetzlicher Anspruch auf Osteopathie, jedoch
              bieten viele Krankenkassen einen variablen Zuschuss als
              Zusatzleistung an, abhängig von der Kasse und der Anzahl der
              Sitzungen.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Eine ärztliche Verordnung und die Mitgliedschaft des Osteopathen
              in einem anerkannten Berufsverband sind erforderlich, um die
              Kostenübernahme durch die Krankenkasse zu ermöglichen.
            </p>
          </div>
        );
      case "skinCancer":
        return (
          <div>
            <h2 className="font-bold  my-2">Hautkrebsvorsorge</h2>
            <p className="break-words whitespace-normal">
              Zur Hautkrebsvorsorge gehören regelmäßige Untersuchungen zur
              Früherkennung von Hautkrebs. Diese Untersuchung kann helfen,
              Hautkrebs in einem frühen Stadium zu diagnostizieren und somit die
              Heilungschancen zu verbessern. Ab einem Alter von 35 Jahren haben
              gesetzlich Krankenversicherte in Deutschland alle zwei Jahre
              Anspruch auf eine Untersuchung zur Hautkrebs-Früherkennung.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen bieten häufigere Vorsorgeuntersuchungen an.
              Auch unter 35 Jahren kann eine kostenlose Auflichtmikroskopie oder
              zusätzliche Leistungen über den gesetzlichen Rahmen hinaus
              angeboten werden.
            </p>
          </div>
        );
      case "colorectalCancer":
        return (
          <div>
            <h2 className="font-bold  my-2">Darmkrebsvorsorge</h2>
            <p className="break-words whitespace-normal">
              Darmuntersuchung zur Früherkennung von Tumoren, zum Beispiel
              mithilfe einer Darmspiegelung.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Ab einem Alter von 50 Jahren bieten alle Krankenkassen jährlich
              einen Stuhltest auf Blut an. Männer ab 50 und Frauen ab 55 Jahren
              können bis zu zwei Darmspiegelungen im Abstand von 10 Jahren
              durchführen lassen. Einige Kassen übernehmen die Kosten bereits
              vor dem 50. bzw. 55. Lebensjahr.
            </p>
          </div>
        );
      case "breastCancer":
        return (
          <div>
            <h2 className="font-bold  my-2">Brustkrebsvorsorge</h2>
            <p className="break-words whitespace-normal">
              Die Brustkrebsvorsorge-Untersuchungen helfen dabei, mögliche
              Tumore frühzeitig zu erkennen, zum Beispiel durch eine
              Mammographie.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Die Brustkrebsvorsorge ist für Frauen ab 30 Jahren kostenlos.
              Einige Kassen übernehmen die Kosten früher oder bieten zusätzliche
              Leistungen wie Selbstuntersuchungskurse an.
            </p>
          </div>
        );

      case "artificialInsemination":
        return (
          <div>
            <h2 className="font-bold  my-2">Künstliche Befruchtung</h2>
            <p className="break-words whitespace-normal">
              Wenn eine natürliche Befruchtung nicht möglich ist, bietet die
              künstliche Befruchtung, wie zum Beispiel die
              In-vitro-Fertilisation, eine wertvolle Unterstützung, um den
              Kinderwunsch zu erfüllen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Gesetzliche Krankenkassen übernehmen 50 % der Kosten für eine
              begrenzte Anzahl von Behandlungsversuchen. Einige Krankenkassen
              bieten zusätzlich weitere Unterstützung, zum Beispiel durch
              zusätzliche Versuche oder höhere Kostenübernahme.
            </p>
          </div>
        );
      case "midWifeServices":
        return (
          <div>
            <h2 className="font-bold  my-2">Hebammen / Geburtshilfe</h2>
            <p className="break-words whitespace-normal">
              Bei der Hebammenrufbereitschaft steht eine vertraute Hebamme rund
              um die Uhr bereit, um bei der Geburt anwesend zu sein, sobald es
              losgeht.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Es gibt keinen gesetzlichen Anspruch auf die
              Hebammenrufbereitschaft. Viele Krankenkassen bieten jedoch als
              Zusatzleistung einen variierenden Zuschuss an.
            </p>
            <h2 className="font-bold  my-2">Bitte beachten:</h2>
            <p className="break-words whitespace-normal">
              Nicht alle Hebammen bieten diesen besonderen Service an.
            </p>
          </div>
        );
      case "childrenInsepection":
        return (
          <div>
            <h2 className="font-bold  my-2">Kinder- und Jugendmedizin</h2>
            <p className="break-words whitespace-normal">
              Zusätzliche Vorsorgeuntersuchungen wie die U10, U11 und J2 helfen
              dabei, Entwicklungs- und Verhaltensauffälligkeiten bei Ihren
              Kindern frühzeitig zu erkennen und gezielt zu unterstützen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Alle Krankenkassen übernehmen die gesetzlich vorgeschriebenen
              Untersuchungen (U1 bis U9 und J1). Viele Kassen bieten darüber
              hinaus als Zusatzleistung die Kostenübernahme für weitere
              Untersuchungen, wie die U10, U11 und J2, an.
            </p>
          </div>
        );
      case "hotline":
        return (
          <div>
            <h2 className="font-bold  my-2">Service – servicetelefon 24/7</h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen bieten die Regelung wichtiger Anliegen über
              eine Service-App an. Versicherte können viele ihrer Anliegen
              bequem und zeitsparend digital erledigen. Über die App lassen sich
              Anträge einreichen, Gesundheitsbescheinigungen abrufen,
              persönliche Daten unkompliziert ändern und der Kontakt zur
              Krankenkasse sicher herstellen. Darüber hinaus umfassen viele
              dieser Apps zusätzliche Funktionen wie das Sammeln von
              Bonuspunkten, die Teilnahme an Gesundheitsprogrammen oder den
              Zugriff auf nützliche Präventionsangebote. So erleichtert die
              Service-App den Versicherten den Zugang zu wichtigen Informationen
              und Dienstleistungen – ganz ohne lange Wartezeiten oder den Gang
              zur Filiale.
            </p>
          </div>
        );
      case "telemedicine":
        return (
          <div>
            <h2 className="font-bold  my-2">Service - TeleMedizin</h2>
            <p className="break-words whitespace-normal">
              Telemedizin ist die moderne Art der medizinischen Versorgung über
              Videotelefonie oder andere Kommunikationsmittel. So können
              Diagnosen, Beratungen und Behandlungen bequem aus der Ferne
              erfolgen. Ein klassisches Beispiel ist die Videosprechstunde, bei
              der Patienten ohne den Gang in die Praxis ärztlichen Rat einholen
              können. Besonders in ländlichen Gebieten oder für Menschen mit
              eingeschränkter Mobilität schafft Telemedizin einen
              unkomplizierten Zugang zu medizinischer Betreuung und verbessert
              somit die Gesundheitsversorgung nachhaltig.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen unterstützen ihre Versicherten bei der Nutzung
              von Telemedizin, indem sie die Kosten für digitale Arztbesuche,
              wie zum Beispiel Videosprechstunden, übernehmen. Zusätzlich bieten
              die meisten Krankenkassen spezielle Apps und Online-Portale an,
              über die Versicherte bequem telemedizinische Leistungen in
              Anspruch nehmen können.
            </p>
          </div>
        );
      case "pregnancyCare":
        return (
          <div>
            <h2 className="font-bold  my-2">Schwangerschaftsvorsorge</h2>
            <p className="break-words whitespace-normal">
              Zusätzliche Vorsorgeuntersuchungen können über den gesetzlichen
              Standard hinausgehen. Da jede Schwangerschaft individuell ist,
              sollten Sie bei der Wahl Ihrer Krankenkasse prüfen, welche
              Zusatzleistungen angeboten werden, und sich im Voraus medizinisch
              beraten lassen.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Gesetzliche Krankenkassen decken notwendige Vorsorgeuntersuchungen
              ab. Manche bieten zusätzliche Leistungen, oft im Rahmen eines
              anteilig nutzbaren Gesundheitsbudgets.
            </p>
          </div>
        );
      case "medicalSportExamination":
        return (
          <div>
            <h2 className="font-bold  my-2">Sport & Bewegung</h2>
            <p className="break-words whitespace-normal">
              Alle körperlichen Aktivitäten, die zur Gesundheitsförderung und
              Prävention von Krankheiten beitragen. Dies kann über gezielte
              Kurse, Fitnessstudiobesuche, Vereinssport oder ärztlich verordnete
              Bewegungsprogramme geschehen. Krankenkassen unterstützen solche
              Maßnahmen finanziell, um einen gesunden Lebensstil zu fördern und
              langfristige Gesundheitskosten zu senken.
            </p>
            <h2 className="font-bold  my-2">
              Wie unterstützen die Krankenkassen dabei?
            </h2>
            <p className="break-words whitespace-normal">
              Viele Krankenkassen unterstützen ihre Versicherten aktiv bei der
              Förderung ihrer Gesundheit durch sportliche Aktivitäten. So werden
              häufig bis zu zwei Sportkurse pro Jahr mit einem Zuschuss von
              mindestens 75 Euro gefördert. In der Regel übernehmen die
              Krankenkassen bis zu 80 % der Kursgebühren, vorausgesetzt, die
              Teilnahmequote liegt bei mindestens 80 %. Außerdem bieten einige
              Krankenkassen eine direkte Erstattung von 25 Euro für Aktivitäten
              im Fitnessstudio oder im Sportverein an. Im Rahmen der
              Präventionsförderung beteiligen sich Krankenkassen auch an
              Programmen zur Gesundheitsförderung und übernehmen dabei ebenfalls
              bis zu 80 % der Kosten für entsprechende Maßnahmen.
            </p>
          </div>
        );
      default:
        return (
          <div>
            <h1>Default Template</h1>
            <p>This is the default content.</p>
          </div>
        );
    }
  };

  return (
    <div className="text-left bg-white text-gray-800 border border-gray-200 shadow-xs rounded-md p-4 max-w-sm z-50">
      {renderTemplate()}
    </div>
  );
};

export default ToolTipContentServices;
