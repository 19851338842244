import logo from "../../assets/logos/logo-dentnet.png";

const Header = () => (
  <header className="bg-white shadow-md">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center py-6">
        <a href="https://www.dentnet.de/krankenkassen/wechseln">
          <img src={logo} alt="DentNet Logo" className="h-8" role="img" />
        </a>
      </div>
    </div>
  </header>
);

export default Header;
