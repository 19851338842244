import { mergeQuestionsByPrefix } from "./utils";

const API_BASE_URL = process.env.REACT_APP_API_BASEURL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchAllInsurances = async (type="all") => {
  console.log('Fetching all insurances...');
  const response = await fetch(`${API_BASE_URL}/get/insurances/${type}`, {
    headers: {
      'Authorization': `Bearer ${API_KEY}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch insurances');
  const data = await response.json();

  //wish of the customer was to remove the teeth section since they are now using the dentnet_default section to account for the teeth data
  //since the teeth section was already created and is intruduced in the database and backend it was better to use this quick fix
  //also keeping in mind the customer could change their mind later
  const updatedData = {
    ...data, // copy the outer object
    insuranceCompanies: data.insuranceCompanies.map((company) => {
      return {
        ...company,
        sections: company.sections.filter(
          (section) => section.title !== "teeth"
        )
      };
    })
  };

  console.log('Fetched insurances: '+type, data);
  return updatedData;
};