import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
const pzrSemiAnnual = ["BKK Mahle","BKK VerbundPlus", "BKK24"]

const InsuranceDetails = ({ insurance, currentInsurance }) => {
  const getServiceValue = (service_id) => {
    const serviceValue = currentInsurance?.filter(
      (item) => item.id === service_id
    )[0]?.value;
    if (serviceValue?.toLowerCase() === "yes") {
      return true;
    }
    if (serviceValue?.toLowerCase() === "no") {
      return false;
    }
    return false;
  };

  return (
    <div className="bg-gray-100 p-2">
      <h2 className="text-xl font-semibold mb-4">Ihre Auswahl</h2>

      <div className="mb-6">
        {insurance.logo ? (
          <img
            src={`${process.env.REACT_APP_API_BASEURL.replace("/api", "")}${
              insurance.logo
            }`}
            alt={`${insurance.companyName} logo`}
            className="max-h-full max-w-full object-contain"
            onError={(e) => {
              console.error("Error loading image:", e);
              e.target.onerror = null;
            }}
          />
        ) : (
          <div className="text-lg font-semibold text-center text-gray-700">
            {insurance.companyName}
          </div>
        )}
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-semibold">Neuer monatlicher Beitrag</h3>
        <p className="text-4xl font-bold">
          {insurance.monthlyCost.toString().replace(".", ",")} €
        </p>
        <p
          className={`text-sm font-bold ${
            parseFloat(insurance.savings) > 0
              ? "text-[#44AC34]"
              : "text-red-500"
          } mt-1`}
        >
          {parseFloat(insurance.savings) > 0
            ? `${insurance.savings
                .toString()
                .replace(".", ",")} € pro Jahr sparen`
            : ""}
        </p>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">DentNet inklusive</h3>
        <ul className="space-y-2">
          {(getServiceValue("pzrannual_dentnet") && !pzrSemiAnnual.includes(insurance.companyName)) && (
            <li className="flex items-center">
              <FaCheck className="text-[#44AC34] mr-2" />
              <span>Jährliche Professionelle Zahnreinigung</span>
            </li>
          )}
          {pzrSemiAnnual.includes(insurance.companyName) && (
            <li className="flex items-center">
              <FaCheck className="text-[#44AC34] mr-2" />
              <span>Halbjährliche Professionelle Zahnreinigung</span>
            </li>
          )}
          {getServiceValue("pzrpregnant_dentnet") && (
            <li className="flex items-center">
              <FaCheck className="text-[#44AC34] mr-2" />
              <span>Zahnreinigung für Schwangere</span>
            </li>
          )}

          {getServiceValue("prothesis_dentnet") && (
            <li className="flex items-center">
              <FaCheck className="text-[#44AC34] mr-2" />
              <span>Zahnersatz mit fünf Jahren Garantie</span>
            </li>
          )}
          {getServiceValue("orthodontic_splint") && (
            <li className="flex items-center">
              <FaCheck className="text-[#44AC34] mr-2" />
              <span>Zahnkorrektur mit Clear Alignern</span>
            </li>
          )}
          {getServiceValue("implant") && (
            <li className="flex items-center">
              <FaCheck className="text-[#44AC34] mr-2" />
              <span>Implantate zu Festpreisgarantien</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default InsuranceDetails;
